<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import workGroupStoreModule from '@/store/modules/workGroup'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧组件
    AddGroup: () => import('@/components/workGroup/left/AddGroup'),
    AddGroupUser: () => import('@/components/workGroup/left/AddGroupUser'),
    // 底部组件
    IndexTable: () => import('@/components/workGroup/bottom/IndexTable'),
    GroupDetail: () => import('@/components/workGroup/bottom/GroupDetail')
    // 右侧组件

  },
  created () {
    if (!this.$store.hasModule('workGroup')) {
      this.$store.registerModule('workGroup', workGroupStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setLeftComponent('AddGroup')
    this.setBottomComponent('IndexTable')
    this.setRightComponent('')
  },
  destroyed () {
    this.$store.commit('reset_state')
    this.$store.unregisterModule('workGroup')

    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
