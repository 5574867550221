import { getWorkGroup, getWorkgroupPage } from '@/api/os/workgroup'

const getDefaultState = () => {
  return {
    workGroupQuery: {},
    workGroupData: {},
    workGroupId: null,
    workGroupUserData: {}
  }
}

const state = getDefaultState()

const workGroup = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_work_group_data (state, data) {
      state.workGroupData = data
    },
    set_work_groupId (state, data) {
      state.workGroupId = data
    },
    set_work_group_user_data (state, data) {
      state.workGroupUserData = data
    }
  },
  actions: {
    /**
     * 获取工作小组数据
     * @param {object} param0
     * @param {object} query
     */
    getWorkGroupData ({ state, commit }, query) {
      const newQuery = Object.assign(state.workGroupQuery, query)
      return new Promise((resolve) => {
        getWorkgroupPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_work_group_data', res)
          } else {
            commit('set_work_group_data', {})
          }
          resolve(res)
        })
      })
    },
    /**
     * 获取工作小组用户
     */
    getWorkGroupUserData ({ state, commit }, id) {
      const workGroupId = id || state.workGroupId
      return new Promise((resolve) => {
        getWorkGroup({ workGroupId }).then(res => {
          if (res && !res.errcode) {
            commit('set_work_group_user_data', res)
          } else {
            commit('set_work_group_user_data', {})
          }
          resolve(res)
        })
      })
    }
  }
}

export default workGroup
